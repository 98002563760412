:root {
    /* --secondary-bg-color: var(--tg-theme-secondary-bg-color);
    --bg-color: var(--tg-theme-bg-color);
    --link-color: var(--tg-theme-link-color);
    --button-text-color: var(--tg-theme-button-text-color);
    --button-color: var(--tg-theme-button-color);
    --hint-color: var(--tg-theme-hint-color); */

    /* --secondary-bg-color: #49515C;
    --bg-color: #e8e2ca;
    --link-color: #358ABA;
    --button-text-color: #eff1f1;
    --button-color: #256EA2;
    --hint-color: #8a939e; */

    --btn-bg-color: #ABABB0;
    --text-light-color: #F0EBED;
    --text-mini: #86EFAC;
    --item-bg: #202020;
    --btn-bg-color2: #86EFAC;
    --btn-bg-color3: #96969C;

    /* variant1 */
    --text-label-color: #B4B4B4;
    --text-color: #FFFFFF;
    --text-green: #86EFAC;

    --bg-container-color: #333333;
    --divider-color: rgba(151, 151, 151, 0.8);

    --with-elements: 350px;
}

.sale-label-l {
    color: var(--text-light-color);
    width: 50%;
    text-align: left;

}

.sale-label-r {
    color: var(--text-light-color);
    width: 50%;
    text-align: right;

}



.text-dark-color {
    color: var(--btn-bg-color);
}

.bg-dark-color {
    background-color: var(--item-bg);
}

select {
	appearance: none;
}

.trade-menu-container {
    padding-top: 8px;
    width: var(--with-elements);
    height: 295px;

    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}

.container-menu {
    width: var(--with-elements);
}

.title-last-deals {
    margin-left: 17px;
    margin-top: 41px;
    
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    
    text-align: left;
    color: #B4B4B4;
}

.bg-input {
    background:none;
    border: none;
    outline: none;
}

.title-myorders {
    color: var(--btn-bg-color);
}



.trade-menu-plus-col {
    color: var(--text-light-color);
    width: 10vw;
}

.trade-menu-text-col {
    width: fit-content;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.trade-menu-text-col-2 {
    width: fit-content;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    color: #86EFAC;
}

.button-trade-menu {
    height: 56px;
    /* padding-top: 5px; */
    /* padding-bottom: 5px; */
    cursor: pointer;
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding-left: 17px;
    padding-right: 17px;
}

.button-trade-menu:hover  {
    font-weight: bold ;
}

.button-currency-settings {
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.button-currency-settings:hover  {
    /* font-weight: bold ; */
}



.divider {
    width: 300px;
    height: 0px;

    border: 1px solid rgba(255, 255, 255, 0.1);
}

.divider-currency {
    margin-top: 1vh;
    margin-bottom: 1vh;
    height: 0.5px;
    background-color: var(--divider-color);
}

.divider-test-order {
    margin-top: 1vh;
    margin-bottom: 1vh;
    height: 0.5px;
    width: 88vw;
    margin-left: -10px;
    background-color: var(--bg-color);
}

.m-m-10 {
    margin-right: -10px;
}

.trade-menu-arrow-col {
    color: var(--text-light-color);
    width: 5vw;
}

.trade-menu-user-col {
    color: var(--text-light-color);
    width: 10vw;
}

.currency-settings-container {
    background-color: var(--item-bg);
    position: relative;
    border-radius: 10px;
    padding: 10px;
}

.w-47 {
    
    width: 47%;
}

.currency-settings-item-col1 {
    color: var(--text-light-color);
    display: flex;
    justify-content:flex-start;
    width: 50%;
    font-size: 16px;
}

.currency-settings-item-col2 {
    color: var(--text-light-color);
    display: flex;
    justify-content:flex-end;
    width: 50%;
    height: 25px;
    font-size: 14px;
}

.method-pay-col2 {
    color: var(--text-light-color);
    display: flex;
    justify-content:flex-end;
    width: 30vw;
}

.t-left-align {
    text-align: left;
}

.mini-info {
    font-size: 13px;
    color: var(--text-mini)
}

.time-limit {
    /* top: 12px;
    right: 10vw;;
    position: absolute; */
    background-color: var(--item-bg);
    border-radius: 5px;
    padding: 5px;
    height: 30px;
}

.button-next {
    background-color: var(--btn-bg-color2);
    color: var(--text-light-color);
    border-radius: 16px;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.button-next-grey {
    background-color: var(--btn-bg-color3);
    color: var(--text-light-color);
    border-radius: 16px;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
}

.button-cancel-deal {
    background-color: var(--btn-bg-color3);
    color: var(--text-light-color);
    border-radius: 16px;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
}



.method-pay-text {
    text-align: left;
}

.method-name-col {
    text-align: left;
    width: 70vw;
    color: var(--text-light-color);
}

.test-order-container {
    background-color: var(--item-bg);
    border-radius: 5px;
    padding: 10px;
}

.test-order-col1 {
    width: 60vw;
}

.test-order-col2 {
    width: 25vw;
}

.test-order-price {
    text-align: left;
    font-size: 20px;
    color: var(--text-light-color);
}

.test-order-text {
    text-align: left;
    font-size: 10px;
    color: var(--text-mini);
}

.test-order-buy {
    font-size: 15px;
    padding: 5px;
    color: var(--text-light-color);
    background-color: var(--btn-bg-color3);
    border-radius: 10px;
}

.test-order-info-col {
    color: var(--text-light-color);
    justify-content:start;
    width: 40vw;
}

p {
    width: fit-content;
}

.selectdiv {
    position: relative;
    /*Don't really need this just for demo styling*/
    
    float: left;
    min-width: 200px;
    margin: 50px 33%;
  }
  
  /* IE11 hide native button (thanks Matt!) */
  select::-ms-expand {
  display: none;
  }
  
  .selectdiv:after {
    content: '<>';
    font: 17px "Consolas", monospace;
    color: #333;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 11px;
    /*Adjust for position however you want*/
    
    top: 18px;
    padding: 0 0 2px;
    border-bottom: 1px solid #999;
    /*left line */
    
    position: absolute;
    pointer-events: none;
  }
  
  .selectdiv select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add some styling */
    
    display: block;
    width: 100%;
    max-width: 320px;
    height: 50px;
    float: right;
    margin: 5px 0px;
    padding: 0px 24px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #cccccc;
    -ms-word-break: normal;
    word-break: normal;
  }

  .ptp-container {
    background-image: url('../../static/images/salut.jpg');
    background-repeat: no-repeat, repeat;
    background-size: cover;
    height: 100vh;
  }

  .buyer-item {
    background-color: var(--item-bg);
    color: var(--text-light-color);
    border-radius: 16px;
    padding: 10px;
  }

  .salute-text1 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }

  .salute-text2 {
    color: var(--btn-bg-color3);
    font-size: 15px;
  }

  .salute-container {
    padding-top: 30vh;
  }

  .deal-item {
    background-color: var(--hint-color);
  }

  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  .loader-mini {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



  ::placeholder {
    color: var(--btn-bg-color);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: var(--btn-bg-color);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: var(--btn-bg-color);
  }

  .line-green {
    height: 0px;
    width: 129px;

    border: 1px solid #86EFAC;
  }


  .open-chat-btn {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    /* display: flex;
    align-items: center; */
    text-align: center;

    color: #DADADA;
  }

 

