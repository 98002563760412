.send-container {
    width: var(--with-elements);
}

.title-send {
    

    text-align: left;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.title-from {
    color: var(--btn-bg-color);
    text-align:left;
    font-size: 14px;
}

.address-item {
    margin-top: 20px;
    width: var(--with-elements);
    height: 60px;

    border: 1px solid #585858;
    /* shadow/sm */

    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    border-radius: 6px;

    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.address-item-2 {
    position: absolute;
    background-color: var(--item-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid var(--btn-bg-color);
}

.confirm-data {
    background-color: var(--item-bg);
    border-radius: 16px;
}

.trs-wait {
    background-color: var(--item-bg);
    border-radius: 16px;
}

.trs-text-block {
    text-align: left;
    width: 60vw;
}

.trs-wait-logo {
    width: 20vw;
}

.trs-text-1 {
    color: var(--text-light-color);
    text-align:left;
    font-size: 16px;
    font-weight: 600;
}

.trs-text-2 {
    color: var(--btn-bg-color);
    text-align:left;
    font-size: 12px;
    font-weight: 400;
}

.address-item-col1 {
    /* padding: 10px; */
    width: 70%;
    /* display: flex;
    align-items: center; */
}

.address-item-col2 {
        margin-left: 20px;
}

.address-circle {
    background-color: var(--btn-bg-color);
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.svg-circle {
    /* margin-left: 13px; */
    display: flex;
    align-items: center;
    padding: 0;
    height: 20px;
    width: 21px;
}

.send-address {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px 13px;
    gap: 8px;

    width: var(--with-elements);
    height: 42px;

    background: rgba(255, 255, 255, 0.1);
    /* shadow/sm */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.address-to-input-2 {
    outline: none;
    border: none;
    background:none;

    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    color: #FFFFFF;

    caret-color: #FFFFFF;
}

.address-to-input {
    outline: none;
    border: none;
    background:none;

    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */


    color: #86EFAC;
}

.send-text {
    width: 240px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    color: #FFFFFF;
}

.send-q-input {
    background-color: #000000;
    caret-color: var(--text-mini);
    color: var(--text-light-color);
    /* color: 'red'; */
    border: none;
    outline: none;
    text-align: right;
    font-size: 30px;
}



.address-to-input::placeholder {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    color: rgba(255, 255, 255, 0.5);
}

.txt-r {
    color: var(--btn-bg-color);
    text-align: right;
}

.txt-l {
    color: var(--btn-bg-color);
    text-align: left;
}

.txt-r-w {
    color: var(--text-light-color);
    text-align: right;
    font-weight: 500;
}

.txt-l-w {
    color: var(--text-light-color);
    text-align: left;
    font-weight: 500;
}

.send-divider { 
    height: 0px;

    border-bottom: 0.5px solid var(--btn-bg-color);
    /* transform: matrix(1, 0, 0, 1, 0, 0); */
    margin-left: 15px;
    margin-right: 15px;
}

.button-send-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    opacity: 0.84;
}

.button-send-bg {
    background: #3D3D3D;
    border-radius: 5px;
}

.button-active-send-bg {
    background: #86EFAC;
    border-radius: 5px;
}

.disable-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #979797;
}

.green-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #86EFAC;
}

.active-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #2B2B2B;
}

.mt-20 {
    margin-top: 20px;
}

.container-balance {
    display: flex;
    justify-content: space-between;
}

.your-balance-text {
    margin-top: 4px;
    margin-left: 14.47px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 10.5px;
    line-height: 20px;
    /* identical to box height, or 190% */


    color: #B4B4B4;
}

.your-balance-q {
    margin-top: 4px;
    margin-right: 11.13px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 10.5px;
    line-height: 20px;
    /* identical to box height, or 190% */


    color: #86EFAC;
}

.not-valid {
    color: red;
}



