.chat-container {
    display: flex;
    justify-content: center;
}

.modal-send {
    position: absolute;
    top: 10vh;
    left: calc(50% - 70vw/2 - 1px);
    width: 70vw;
    /* height: 45vh; */
    background-color: #FFFFFF;
    z-index: 10;
    border-radius: 10px;
    padding-bottom: 10px;
}

.img-send {
    margin: 10px;
    max-width: 65vw;
    max-height: 30vh;
    border-radius: 7px;
}

.message-img {
    max-width: 50vw;
    max-height: 50vw;
    border-radius: 7px;
}

.chat-wrap-input {
    width: 246px;
    height: 45px;

    background: rgba(255, 255, 255, 0.1);
    /* shadow/sm */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 0;
    display: flex;
    align-items: center;
}

.message-input {
    caret-color: var(--text-mini);
    color: var(--text-light-color);
    /* color: 'red'; */
    border: none;
    outline: none;
    text-align: left;
    font-size: 15px;
    padding-left: 5px;
    resize: none;

    width: 246px;
    height: 45px;

    /* background: rgba(255, 255, 255, 0.1); */
    background: none;
    /* shadow/sm */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.btn-send {
    background: #86EFAC;
    border-radius: 5px;
    width: 45px;
    height: 45px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-send-file {
    width: 60%;
    color: var(--text-light-color);
    border-radius: 10px;
    background-color: var(--item-bg);
    padding: 5px;
}

.file-upload {
    width: 5%;
}

.file-upload>input {
    display: none;
}

.container-messages {
    height: 80vh;
    overflow: scroll;
    padding-left: 5px;
    padding-right: 5px;
}

.msg-chat {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */

    /* display: flex;
    align-items: center; */

    color: #000000;
}

.sender-msg-chat {
    text-align: left;
    color: var(--btn-bg-color);
}

.msg-chat-recipient {
    text-align: right;
    color: var(--text-light-color);
}

.recipient-msg-chat {
    text-align: right;
    color: var(--btn-bg-color);
}

.container-message-item {
    position: relative;
    background: #FFFFFF;
    border-radius: 10px;
    width: fit-content;

    padding: 15px 20px;
    margin-left: 21px;
}

.container-my-message-item {
    position: relative;
    background: #86EFAC;
    border-radius: 10px;
    width: fit-content;
    padding: 15px 20px;
    margin-right: 21px;
}

.chat-title-text {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    text-align: center;

    color: #86EFAC;
}

.chat-title-text-2 {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */

    /* display: flex;
    align-items: center; */
    text-align: center;

    color: #8E8E93;
}

.chat-cntr-input {
    background-color: #161617;
    height: 92px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left:0;

    display: flex;
    justify-content: center;
}

.msg-svg {
    position: absolute;
    left: -4px;
    top: calc(50% - 5.5px)
}

.msg-svg-g {
    position: absolute;
    right: -4px;
    top: calc(50% - 5.5px)
}