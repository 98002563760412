.button-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;
    width: 25vw;
    height: 16vw;
    /* background-color: var(--tg-theme-button-color); */
    /* background-color: #256EA2; */
    /* color: var(--tg-theme-button-text-color); */
    /* color: var(--text-light-color); */
    /* background-color: var(--btn-bg-color); */

    background: rgba(134, 239, 172, 0.08);
    backdrop-filter: blur(2px);

    /* border: 1px solid black; */
    border-radius: 18px;
    cursor: pointer;
}

.green_delta {
    color: #5EA177;
    margin-left: 3px;
}

.red_delta {
    color: brown;
    margin-left: 3px;
}

.button-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;
    width: 28vw;
    height: 12vw;
    cursor: pointer;
}

.text-button-menu {
    margin-top: 12px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;
    color: #FFFFFF;
}

.text-token-manage {
    margin-top: 58px;
    text-align: left;
    width: var(--with-elements);

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    color: #B4B4B4;
}

.container-buttons {
    z-index: 11;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-top: -45px;
    width: var(--with-elements);
    height: 90px;

    background: #141414;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.menu-button-svg-container {
    width: 8vw;
    height: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--btn-bg-color); */
    /* background-color: #E4BB2D; */
    /* border-radius: 4vw; */
}

.menu-button-svg-container-p {
    display: flex;
    justify-content: center;
}

.button-menu-text {
    /* color: #E4BB2D; */
    color: var(--text-color);
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    font-size: 11px;
    line-height: 13px;
    backdrop-filter: none;
}

.title-balance {
    width: var(--with-elements);
    height: 16px;
}

.balance-main {
    position: absolute;
    top: 24px;
    left: 0px;
    width: 100%;
    /* height: 10vh; */
    color: #FFFFFF;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 638;
    font-size: 45px;
    line-height: 53px;
    /* display: flex;
    align-items: flex-end; */
    text-align: center;
    /* opacity: 0; */

    animation-name: show;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-timing-function: ease;

    visibility: visible;
}

.hide-balace-load {
    /* animation: hide 1s ease  */
    animation-name: hide;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    /* opacity: 0; */
}

@keyframes show {
	0% {
		opacity: 0;
	}
    
	100% {
		opacity: 1;
        /* display: none; */
	}
}

@keyframes hide {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
        /* display: none; */
	}
}

.home-container-balance {
    position: relative;
    background: linear-gradient(180deg, rgba(134, 239, 172, 0) 6.61%, rgba(134, 239, 172, 0.279895) 44.03%, #86EFAC 100%);
    border-radius: 10px;
    /* height: 230px; */
    /* height: 205px; */

    visibility: visible;
}

.h-205 {
    height: 205px; 
}

.h-230 {
    height: 230px; 
}

.grow {
    /* animation-name: grow;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-timing-function: ease; */
}

.grow-hide {
    /* animation-name: growhide;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-timing-function: ease; */
}

@keyframes growhide {
	0% {
		height: 205px;
        opacity: 1;
	}
    
	100% {
		height: 230px;
        opacity: 0;
	}
}

@keyframes grow {
	0% {
		height: 205px;
	}
	100% {
		height: 230px;
	}
}

.home-container-balance-load {
    top:0;
    z-index: 10;
    position: absolute;
    height: 205px;
    /* Gray 1 */
    width: 100%;
    background: #333333;
    border-radius: 10px;
}

.wallet-item {
    /* display: flex; */
    align-items: center;
    /* justify-content:space-between; */
    padding: 15px 18px;
    /* gap: 113px; */
    width: var(--with-elements);
    height: 70px;

    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

.wallet-item-load {
    z-index: 11;
    position: absolute;
    width: var(--with-elements);
    height: 70px;

    background: radial-gradient(101.38% 2292.84% at 100% 64.79%, #86EFAC 0%, #5EA177 15%, #232323 49%, #232323 51%, #5EA177 74.25%, #86EFAC 100%);
    /* opacity: 0.8; */
    border-radius: 10px;
    background-size: 200%;
}

.wallet-item-load-1 {
    z-index: 11;
    position: absolute;
    width: var(--with-elements);
    height: 70px;

    background: radial-gradient(101.1% 2286.42% at 0% 50%, #86EFAC 0%, #5EA177 13.31%, #232323 100%);
    /* opacity: 0.8; */
    border-radius: 10px;
    background-size: 200%;
}
.wallet-item-load-2 {
    z-index: 11;
    position: absolute;
    width: var(--with-elements);
    height: 70px;

    background: radial-gradient(101.1% 2286.42% at 0% 50%, #232323 0%, #5EA177 61.07%, #86EFAC 88.27%);
    /* opacity: 0.8; */
    border-radius: 10px;
    background-size: 200%;
}

.balance-load {
    z-index: 11;
    margin-top: 20px;
    background: radial-gradient(101.38% 2292.84% at 100% 64.79%, #86EFAC 0%, #5EA177 15%, #232323 49%, #232323 51%, #5EA177 74.25%, #86EFAC 100%);
    opacity: 0.8;
    border-radius: 10px;
    height: 71px;
    width: var(--with-elements);
    background-size: 200%;

    /* animation: gradient 1s ease infinite; */
}

.anim-load-inv {
    animation: animloadinv 2s ease infinite;
}

@keyframes animloadinv {
	0% {
		opacity: 0;
	}
    50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.anim-load {
    animation: animload 2s ease infinite;
}

@keyframes animload {
	0% {
		opacity: 1;
	}
    50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.gradient {
    animation: gradient 2s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.gradient-back {
    animation: gradientback 2s ease infinite;
}

@keyframes gradientback {
	0% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}

.balance-main-sign {
    /* height: 10vh; */
    /* color: #B4B4B4; */
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 638;
    font-size: 45px;
    line-height: 53px;
    /* identical to box height */

    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.015em;

    color: rgba(255, 255, 255, 0.7);
    font-stretch: 100;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    /* display: flex;
    align-items: flex-end; */
    text-align: center;
}



.balance-label {
    width: 50%;
    
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    padding: 0;
    text-align: left;

    color: #86EFAC;
}

.bottom-balance {
    /* margin-bottom: 30px; */
    color: var(--text-label-color);
    width: 45%;
    padding: 0;

    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align:right;

    color: #86EFAC;
    font-stretch: 151;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 32;

    
}

.divider-v {
    left: 70px;
    position: absolute;
    width: 90px;
    height: 0px;

    border: 1px solid rgba(255, 255, 255, 0.06);
    transform: rotate(90deg);
}

.divider-v2 {
    left: 200px;
    position: absolute;
    width: 90px;
    height: 0px;

    border: 1px solid rgba(255, 255, 255, 0.06);
    transform: rotate(90deg);
}

.mt-30 {
    margin-top: 33px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-18 {
    margin-top: 18px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-21 {
    margin-top: 21px;
}

.bottom-balance-percent {
    margin-left: 10px;
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;

    width: 58px;
    height: 32px;

    background: #64B782;
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 12px;

    /* Inside auto layout */

    /* flex: none;
    order: 1;
    flex-grow: 0; */

    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;

    color: #000000;
    font-stretch: 151;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 32;
}

.token-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.token-balance-text2 {
    white-space: nowrap;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #FFFFFF;
    /* font-stretch: 100;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738; */
}

.token-balance-text {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    white-space: nowrap;
    display: flex;
    align-items: center;

    color: #B4B4B4;
}

.bottom-info {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;

    color: #86EFAC;
}



.devider-item {
    display: flex;
    align-items: center;
    justify-content:flex-end;
    /* height: 50px; */
    padding: 10px;
    width: 90vw;
    /* background-color: var(--item-bg); */
    /* border-radius: 10px; */
    /* margin-right: 10px; */
}

.container-deal {
    display: flex;
    justify-content:space-between;
    height: 77px;
    margin-top: 13px;
    background: rgba(51, 51, 51, 0.5);
    /* opacity: 0.5; */
    border-radius: 10px;
}

.wallet-item-container {
    display: flex;
    justify-content: center;
}

.divider-token {
    width: 260px;
    height: 0px;
    margin-right: 10px;
    border: 1px solid rgba(134, 239, 172, 0.1);
}

.wallet-item-svg-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--btn-bg-color); */
    border-radius: 17px;
    width: 38px;
    height: 38px;
    padding: 0;
}

.wallet-item-info {
    margin-left: 12px;
    width: 73px;
    color: var(--btn-bg-color)
}

.wallet-item-info2 {
    margin-left: 110px;
    /* text-align: right; */
    width: 51px;
    color: var(--btn-bg-color)
}

.label-deal {
    font-size: 14px;
    color: var(--btn-bg-color)
}

.text-deal {
    text-align: left;
    color: var(--text-light-color);
    font-size: 16px;
}

.text-deal-date {

    margin-top: 16px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.text-deal-request-from {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;

    /* width: 104px; */
    height: 18px;

    background: #FF8F8F;
    border-radius: 10px;

    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #333333;

    margin-top: 4px;
}


.text-deal-request-from-b {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;

    /* width: 104px; */
    height: 18px;

    
    background: #86EFAC;
    border-radius: 10px;

    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    
    color: #1C1C1D;

    margin-top: 4px;
}

.text-deal-quantity {
    margin-top: 21px;
    /* margin-right: 17px; */
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* display: flex;
    align-items: center; */
    text-align: right;

    color: #FFFFFF;
    font-stretch: 100;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
}

.text-deal-q-fiat {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: right;
    margin-right: 17px;
    /* identical to box height, or 182% */


    color: #86EFAC;
}

.deal-text-3 {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    /* identical to box height, or 182% */
    text-align: right;

    color: #FFFFFF;
}

.text-deal-r {
    text-align: right;
    color: var(--text-light-color);
    font-size: 16px;
}

.deal-col-1 {
    margin-left: 20px;
}


/* NEW DESIGN */

.color-bg-cntr {
    background: #272728;
    border-radius: 10px;
}

.color-bg-cntr-person {
    background: #333333;
    border-radius: 10px;
}

.color-bg-deal {
    background: rgba(51, 51, 51, 0.5);
    border-radius: 10px;
}

.color-bg-deal-r {
    background: rgba(134, 239, 172, 0.05);
    /* opacity: 0.5; */
    border-radius: 10px;
}

.color-bg-address {
    border: 1px solid #585858;
    /* shadow/sm */

    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    border-radius: 6px;
}

.w-cntr {
    width: var(--with-elements);
}

.w-161 {
    width: 161px;
}

.w-335 {
    width: var(--with-elements);
}

.h-cntr-deal {
    height: 209px;
}

.h-198 {
    height: 198px;
}

.h-177 {
    height: 177px;
}

.h-162 {
    height: 162px;
}

.h-cntr-person {
    height: 104px;
}

.h-118 {
    height: 118px;
}

.h-77 {
    height: 77px;
}

.h-60 {
    height: 60px;
}

.h-47 {
    height: 47px;
}

.h-29 {
    height: 34px;
}

.h-30 {
    height: 30px;
}

.item-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */


    color: #FFFFFF;
}

.deal-text {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    text-align: left;

    color: #FFFFFF;
}

.deal-text-2 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    text-align: left;

    /* color: #979797; */
}

.color-q-text {
    color: #FFFFFF;
}

.color-deal-text {
    color: #979797;
}

.color-deal-r-text {
    color: #86EFAC;
}

.title-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    text-align: left;
    color: #FFFFFF;
}

.title-text-g {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    text-align: left;
    color: #86EFAC;
}

.name-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    text-align: center;

    color: #86EFAC;
}

.num-text {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    text-align: center;

    color: #86EFAC;
}

.mini-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;

    text-align: center;

    color: #8E8E93;
}

.mini-text-r {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;

    text-align: center;

    color: #FF8F8F;
}

.send-text-1 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    letter-spacing: 0.085em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.send-text-2 {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #FFFFFF;
}

.pt-26 {
    padding-top: 26px;
}

.pt-22 {
    padding-top: 22px;
}

.p-17 {
    padding-left: 17px;
    padding-right: 17px;
}

.pt-17 {
    padding-top: 17px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-9 {
    padding-top: 9px;
}

.mb-12 {
    margin-bottom: 12px;
}

.ml-7 {
    margin-left: 7px;
}

.ml-12 {
    margin-left: 12px;
}

.mr-17 {
    margin-right: 17px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-21 {
    margin-top: 21px;
}

.mt-18 {
    margin-top: 18px;
}

.mt-17 {
    margin-top: 17px;
}

.mt-13 {
    margin-top: 13px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-6 {
    margin-top: 6px;
}

.w-227 {
    width: 227px;
}

.w-182 {
    width: 180px;
}

.w-130 {
    width: 130px;
}

.h-34 {
    height: 34px;
}

.h-82 {
    height: 82px;
}

.t-a-l {
    text-align: left;
}

.cntr-center {
    display: flex;
    justify-content: center;
}

.cntr-left {
    display: flex;
    justify-content: left;
}

.cntr-between {
    display: flex;
    justify-content:space-between;
}

.cntr-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 19px 11px 14px;

    width: 162.5px;
    height: 79px;
}

.row-2 {
    display: flex;
    justify-content: space-between;
}

.a-c {
    align-items: center;
}

.line {
    width: 294px;
    height: 0px;

    border: 1px solid rgba(255, 255, 255, 0.1);
}

.line-2 {
    width: 301px;
    height: 0px;

    border: 1px solid #353535;
}

.btn-accept-deal {
    width: 157px;
    height: 46px;

    opacity: 0.84;

    background: #86EFAC;
    border-radius: 5px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #2B2B2B;

    /* margin-right: 17px; */
}

.btn-disable-deal {
    /* margin-left: 17px; */
    background: #333333;
    border-radius: 5px;
    width: 130px;
    height: 46px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #FF8F8F;
}

.btn-edit {
    width: 184px;
    height: 35px;
    background: #333333;
    border-radius: 5px 5px 0px 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    justify-content: center;

    color: #86EFAC;
}

.btn-run {
    background: #86EFAC;
    border-radius: 5px 5px 10px 0px;

    width: 151px;
    height: 41px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    justify-content: center;

    color: #272728;
}



