.text_error_appilate {
    color: #FF8F8F;
    font-size: 14px;
    font-family: 'Roboto Flex';
}

.market-container {
    padding-top: 17px;
    width: var(--with-elements);
}

.filter-input {
    position: relative;
    background-color: var(--item-bg);
    outline: none;
    border: none;
    color:  var(--text-light-color);
    font-size: 12;
    margin-top: 3;
    width: 100%;
    text-align: left;
    height: 30px; 
    border-radius: 5px; 
    padding: 5px; 
    z-index: 1;
    /* font-size: 14px; */
}

.container-buy-sale {
    width: 262px;
    height: 36px;
    display: flex;

}

.container-filter-sale-buy {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-header {
    display:flex;
    justify-content: space-between;
    
    height: 66px;
    background: radial-gradient(101.38% 2292.84% at 100% 64.79%, rgba(35, 35, 35, 0.1) 0%, rgba(94, 161, 119, 0.1) 74.25%, rgba(134, 239, 172, 0.1) 100%);
    mix-blend-mode: normal;
    /* opacity: 0.1; */
    border-radius: 10px;
}

.order-item {
    height: 210px;

    background: rgba(51, 51, 51, 0.5);
    border-radius: 10px;

    color:#FFFFFF;
    margin-top: 8px;
    margin-bottom: 8px;
}

.order-item-user {
    position: relative;
    height: 253px;

    background: rgba(51, 51, 51, 0.5);
    border-radius: 10px;

    color:#FFFFFF;
    margin-top: 8px;
    margin-bottom: 8px;
}

.order-price {
    margin-left: 17px;
    text-align: left;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;

    color: #FFFFFF;
}

.order-sale {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 10px;

    width: 89px;
    height: 25px;

    border: 1px solid #FF8F8F;
    border-radius: 10px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #FF8F8F;
    margin-right: 15px;
}

.order-buy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 10px;

    width: 79px;
    height: 25px;

    border: 1px solid #86EFAC;
    border-radius: 10px;


    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #86EFAC;

    margin-right: 15px;
}

.order-request {
    font-size:medium;
    width: 30vw;
    background-color: var(--link-color);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:crimson;
}

.divider-order {
    margin-left: -10px;
    height: 0.5px;
    background-color: var(--btn-bg-color);
    width: calc(100% + 20px);
}

.divider-order-market {
    /* margin-left: -10px; */
    height: 0.5px;
    background-color: var(--btn-bg-color);
    /* width: calc(100% + 20px); */
}

.order-label {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */

    display: flex;
    align-items: center;

    color: #86EFAC;
}

.order-info-3 {
    width: fit-content;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    /* or 138% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #FFFFFF;

    margin-right: 17px;
}

.order-buy_text {
    width: fit-content;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    /* or 138% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #86EFAC;

    margin-right: 17px;
}

.deal-end-text-1 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #86EFAC;
}

.order-line-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 5px 0px; */

    width: 301px;
    height: 7px;
    
}

.deal-line {
    width: 301px;
    height: 0px;

    border: 1px solid #353535;
}

.order-line {
    margin-left: 17px;
    width: 301px;
    height: 0px;

    border: 1px solid #353535;
}

.deal-label-1 {
    width: fit-content;
    height: 19px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    letter-spacing: 0.085em;
    text-transform: uppercase;

    color: #9C9595;
}

.order-label-2 {
    margin-left: 17px;
    width: fit-content;
    height: 19px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    /* display: flex;
    align-items: center; */
    text-align: left;
    letter-spacing: 0.085em;
    text-transform: uppercase;

    color: #9C9595;
}

.deal-label-2 {
    margin-left: 17px;
    height: 19px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    /* display: flex;
    align-items: center; */
    letter-spacing: 0.085em;
    text-transform: uppercase;
    text-align: left;

    color: #9C9595;
}

.order-comment {
    padding-top: 17px;
    background: #272728;
    border-radius: 10px;
    /* height: 165px; */
    padding-bottom: 15px;
}

.order-row-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    
}

.deal-row-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-user-name {
    width: fit-content;
    margin-left: 17px;
    /* margin-top: 10px; */
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.order-info-1 {
    width: fit-content;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    /* or 164% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #FFFFFF;
    margin-right: 20px;
}

.order-info-2 {
    width: fit-content;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    /* or 164% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #86EFAC;

    margin-right: 17px;
}

.bg-order-input {
    background-color:black;
    color: var(--text-light-color);
    /* color: 'red'; */
    border: none;
    outline: none;
    width: fit-content;
    font-size: 25px;
}

.title-buy {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.01em;

    color: #86EFAC;
}

.saler-buyer {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    text-align: center;

    color: #FFFFFF;
}

.title-buy-2 {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.01em;

    color: #FFFFFF;
}

.screen-buy-container {
    width: var(--with-elements);

    
}

.allert-text-2 {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    /* or 138% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #FFFFFF;
}

.alert-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    letter-spacing: 0.085em;
    text-transform: uppercase;

    color: #86EFAC;
}

.deal-text-1 {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    /* or 185% */

    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.timer-text {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 16px;
    /* or 53% */

    /* display: flex;
    align-items: center; */
    text-align: center;

    color: #FFFFFF;
}

.deal-text-3 {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 130%;
    /* or 14px */

    /* display: flex;
    align-items: center; */
    text-align: center;

    color: #86EFAC;
}

.wait-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    text-align: center;

    color: #FFFFFF;
}

.wait-text-1 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    text-align: center;
    letter-spacing: 0.085em;
    text-transform: uppercase;

    color: #86EFAC;
}

.methods-pay {
    /* background-color: var(--button-color); */
    border-radius: 10px;
}

.buy-label {
    margin-left: 10px;
    color: var(--button-text-color);
    width: 40vw;
    text-align: left;
}

.buy-info {
    color: var(--button-text-color);
    width: 40vw;
    text-align: right;
}

.title-select-method {
    color: var(--text-light-color);
    text-align: center;
}

.deal-info-container {
    background-color: var(--item-bg);
    border-radius: 16px;
    padding: 5px;
    color: var(--text-light-color);
}

.select-currency {
    padding: 5px;
    border: none;
    background: none;
    color:  var(--text-light-color);
    font-size: 11;
    margin-top: 3;
    /* border: '0.5px solid grey'; */
    border-radius: 5;
    width: 100%;
    text-align: center;
}

.filter-item {
    width: 22%;
    padding-left: 0px;
    padding-right: 0px;
}

.filter-sale-buy-item {
    width: 131px;
    padding: 0;
}

.is_active {
    background: #585858;
}

.simple-btn {
    color:  var(--text-light-color);
    cursor: pointer;
}

.w-50p {
    width: 50%;
}

.title-buy-mini {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */

    /* display: flex;
    align-items: center; */
    text-align: center;

    color: #86EFAC;

    
}

.title-buy-label {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    color: #86EFAC;
}

.label-deal-fiat {
    color: var(--btn-bg-color);
    font-size: 15px;
    text-align: left;
}


/* market */

.text-market {
    width: fit-content;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.selected-item-buy {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border: 1px solid #585858;
    border-radius: 4px 0px 0px 4px;
}

.selected-item-sale {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border: 1px solid #505050;
    border-radius: 0px 4px 4px 0px;
}

.buy-sale-text {
    /* text-align: center; */
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    /* display: flex; */
    /* align-items: center; */
    text-align: center;

    color: #FFFFFF;
}

.filter-item-quantity {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 7px 9px 13px;
    gap: 4px;

    width: 184px;
    height: 36px;

    border: 1px solid #585858;
    /* shadow/sm */
    z-index: 1;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    border-radius: 6px;
}

.filter-item-currency {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px 7px 9px 13px;
    gap: 4px;

    width: 143px;
    height: 36px;

    border: 1px solid #585858;
    /* shadow/sm */

    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    border-radius: 6px;
}

.filter-item-fiat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px 7px 9px 13px;
    gap: 4px;

    width: 83px;
    height: 36px;

    border: 1px solid #585858;
    /* shadow/sm */

    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    border-radius: 6px;

    z-index: 10;
}

.filter-item-company {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px 7px 9px 13px;
    gap: 4px;

    width: 244px;
    height: 36px;

    border: 1px solid #585858;
    /* shadow/sm */

    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    border-radius: 6px;
}

.filter-item-company-text {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    color: #FFFFFF;
}

.container-buy-input {
    border: 1px solid #585858;
    /* shadow/sm */

    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    border-radius: 6px;

    display: flex;
    justify-content:flex-start;
    align-items: center;
}

.buy-input {
    padding: 6px;
    outline: none;
    border: none;
    background:none;

    
    width: 30px;
}

/* .buy-input::placeholder {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px; */
    /* identical to box height, or 171% */

    /* color: #FFFFFF; */
/* } */

.text-buy {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #FFFFFF;
}

.price-info-buy {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #86EFAC;
}



.message-buy {
    margin-left: 18px;
    width: 299px;
    height: 56px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 130%;
    /* or 14px */

    display: flex;
    align-items: center;

    text-align: left;

    color: #86EFAC;
}

.comment-text {
    width: 100%;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    
    text-align: center;

    color: #FFFFFF;
}

.container-comment-text {
    margin-top: 20px;
    margin-left: 51px;
    width: 233px;
}