.st0 {fill:#f5f8f5}

.st1{fill:#c0e7ef}

.st2{fill:#737473}

.svg-wait {

  animation: rotation 8s infinite;
}

@keyframes rotation {
    12% {transform: rotate(45deg);}
    24% {transform: rotate(90deg);}
    36% {transform: rotate(135deg);}
    48% {transform: rotate(180deg);}
    60% {transform: rotate(225deg);}
    72% {transform: rotate(270deg);}
    84% {transform: rotate(315deg);}
    100% {transform: rotate(360deg);}
}