.address-container {
    display: flex;
    justify-content: center;
    /* padding-top: 10vh; */
}

.widget-container {
    width: var(--with-elements);
}

.address-text {
    margin-top: 4px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    text-align: left;

    color: #86EFAC;
    /* width: 60vw;
    word-break: break-all;
    color: var(--text-light-color) */
}

.address-copy-message {
    position: absolute;
    top: -30px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    text-align: center;
    width: 100%;
    color: #86EFAC;
}

.qr-container {
    height: 186px;

    background: #141414;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.label-address {
    margin-top: 20px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 10.5px;
    line-height: 12px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    text-align: left;
    color: #FFFFFF
}

.share-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #2B2B2B;
}

.copy-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #86EFAC;
}

.button-address-container {
    position: relative;
    margin-top: 42px;
    display: flex;
    justify-content: space-between;
}

.address-copy-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 148px;
    height: 46px;

    opacity: 0.84;
    background: #333333;
    border-radius: 5px;
}

.address-share-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 167px;
    height: 46px;

    opacity: 0.84;
    background: #86EFAC;
    border-radius: 5px;
}

.left-1 {
    left: 20px;
}

.w-60 {
    width: 60vw;
}




.message-address {
    margin-top: 28px;
    text-align: left;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    /* or 16px */


    color: #979797;
}

.pos-abs {
    position: absolute;
    top: 60px;
    left: 0;
}

.pos-abs-t-40 {
    position: absolute;
    top: 40px;
    left: 0;
}